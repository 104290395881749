import { Controller } from "stimulus";
import { Loader } from "@googlemaps/js-api-loader";
import styles from './mapStyle.js';

let loader = null;
const initLoader = (apiKey) => {
  loader = new Loader({
    apiKey: apiKey,
    version: "weekly",
    libraries: ["places"],
  });
}

const iconUrl = '//' + window.location.host + '/_client/images/ch-map.png';

export default class extends Controller {

  static get targets() {
    return [
      
    ];
  }

  initialize() {
    this.markers = JSON.parse(this.element.dataset.markers);
    this.mapMarkers = [];
    this.apiKey = this.element.dataset.key;
    this.zoom = parseInt(this.element.dataset.zoom);

    
    this.options = {
      center: {lat: 0, lng: 0},
      zoom: this.zoom,
      scrollwheel: false,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: styles,
      restriction: {
        // Limit to UK view
        latLngBounds: {
            north: 59.66219,
            south: 49.70317,
            west: -10.86548,
            east: 2.01050,
        },
        strictBounds: true,
      },
    };

    if(!loader && this.apiKey) { 
      initLoader(this.apiKey);
    }
    if(loader) {
        loader.load()
        .then(() => {
            this.createMap();
            this.centreMap();
        })
        .catch((e) => {
        // do something
        });
    }
  }

  createMap() {
    this.map = new google.maps.Map(this.element, this.options);

    this.markers.forEach(marker => {
      const gMarker = new google.maps.Marker({
        position: marker.position,
        map: this.map,
        title: marker.title,
        icon: iconUrl
      });
      
      this.mapMarkers.push(marker);
    });
  }

  centreMap() {
    const bounds = new google.maps.LatLngBounds();
    this.mapMarkers.forEach(marker => {
      const latlng = new google.maps.LatLng(marker.position.lat, marker.position.lng);
      bounds.extend(latlng);
    });
    if(this.mapMarkers.length === 1) {
      this.map.setCenter(bounds.getCenter());
    }
    else {
      this.map.fitBounds(bounds);
    }
  }
};